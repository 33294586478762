<template>
    <div class="form-container">
        <h1 class="headline5 title">
            {{ $t("cp__respondents_list_edit_header_title") }}
        </h1>
        <div class="form">
            <section class="form-content">
                <div
                    class="respondent_property"
                    v-for="(item, index) in headerColumns"
                    :key="index"
                >
                    <TextInput
                        v-if="item.isRequired && item.type === 'text'"
                        class="respondent_column_input"
                        disabled
                        :label="item.label"
                        showRequiredAsterisk
                    />
                    <TextInput
                        v-else-if="item.type === 'text'"
                        class="respondent_column_input"
                        v-model="item.label"
                        hideLabelOnInput
                        :disabled="item.isDefaultColumn"
                        :label="$t('cp__respondents_list_edit_header_field_label')"
                        enableExternalValidation
                        :externalValidationError="$v.headerColumns.$each[index].$error"
                        :externalValidationErrorMessage="
                            resolveErrorMessage($v.headerColumns.$each[index].label)
                        "
                    />
                    <Dropdown
                        v-else
                        class="respondent_column_input"
                        disabled
                        :options="[]"
                        :placeholder="item.label"
                        v-model="item.label"
                        :showRequiredAsterisk="item.isRequired"
                    />
                    <InlineButton v-if="!item.isRequired" @click="remove(index)" grey>
                        <font-awesome-icon slot="icon" :icon="['far', 'times']" size="lg" />
                    </InlineButton>
                </div>
            </section>
            <div class="form-footer">
                <Button buttonStyle="secondary" @click.native="addNewField"
                    >{{ $t("cp__respondents_list_edit_header_add_new_field") }}
                </Button>
                <div class="button-group">
                    <Button buttonStyle="secondary" @click.native="$emit('close')">{{
                        $t("cp__respondents_list_popup_cancel")
                    }}</Button>
                    <Button @click.native="save">{{
                        $t("cp__respondents_list_popup_save")
                    }}</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InlineButton from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/InlineButton.vue";
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import Dropdown from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import cloneDeep from "lodash.clonedeep";
import { validationMixin, resolveErrorMessage } from "@/validation";
import { getHeaderFieldsSchema } from "@/validation/schemas/respondent";

export default {
    name: "RespondentsUpload",
    mixins: [validationMixin],
    components: {
        Button,
        TextInput,
        InlineButton,
        FontAwesomeIcon,
        Dropdown,
    },
    validations() {
        return getHeaderFieldsSchema(this.headerColumns);
    },
    props: {
        columns: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            headerColumns: cloneDeep(this.columns),
        };
    },
    methods: {
        resolveErrorMessage,
        save() {
            this.$v.$touch();
            if (!this.$v.$error) {
                this.headerColumns.forEach((element) => {
                    element.property = element.label.replace(/\s/g, "").toLowerCase();
                });
                this.$emit("save", this.headerColumns);
            }
        },
        addNewField() {
            this.headerColumns.push({ type: "text", isRequired: false });
            setTimeout(() => {
                const inputs = document.getElementsByClassName("respondent_column_input");
                inputs[inputs.length - 1].scrollIntoView();
            }, 100);
        },
        remove(index) {
            this.headerColumns.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "../../style_variables/style_variables.scss";

.form-container {
    width: 100%;
    height: 100%;
}

.title {
    margin-bottom: 40px;
}

.form {
    width: 100%;
    height: 100%;
}

.form-content {
    margin-bottom: 24px;
    max-height: 50vh;
    overflow-y: auto;
}

.form-footer {
    display: flex;
    justify-content: space-between;
}

.button-group {
    display: flex;
    > :first-child {
        margin-right: 12px;
    }
}

.edit-form-footer {
    justify-content: end;
    -webkit-justify-content: flex-end;
}

.respondent_property {
    margin-bottom: 12px;
    margin-right: 16px;
    display: flex;

    &:last-child {
        margin-bottom: 0px;
    }
}

.respondent_column_input {
    width: 450px;
}
</style>
