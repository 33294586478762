var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule_invitations"},[_c('SettingsBlock',{attrs:{"title":_vm.$t('cp__research_invitations__dates__title')}},[_c('SettingsSection',{staticClass:"schedule_date_input",attrs:{"label":_vm.$t('cp__research_invitations__dates__invitations_date__label'),"helpText":_vm.$t('cp__research_invitations__dates__invitations_date__helptext')}},[_c('div',{staticClass:"date_input_container"},[_c('DateInput',{attrs:{"mode":"dateTime","isRequired":"","placeholder":_vm.$t('inputs__date__select_date'),"minDate":new Date(),"locale":_vm.getLanguageCultureCode},model:{value:(_vm.scheduleDate),callback:function ($$v) {_vm.scheduleDate=$$v},expression:"scheduleDate"}})],1)]),_c('SettingsSection',{attrs:{"label":_vm.$t('cp__research_invitations__dates__reminder_date__label'),"helpText":_vm.$t('cp__research_invitations__dates__reminder_date__helptext')}},[_c('Checkbox',{staticClass:"checkbox",attrs:{"label":_vm.$t('cp__research_invitations__dates__reminder_date__checkbox_label')},model:{value:(_vm.reminderEnabled),callback:function ($$v) {_vm.reminderEnabled=$$v},expression:"reminderEnabled"}}),_c('div',{staticClass:"date_input_container"},[_c('DateInput',{attrs:{"mode":"dateTime","isRequired":"","placeholder":_vm.$t('inputs__date__select_date'),"forcePlaceholder":!_vm.reminderEnabled,"minDate":_vm.reminderDateMinDate,"locale":_vm.getLanguageCultureCode,"disabled":!_vm.reminderEnabled,"attributes":[
                        {
                            dates: this.scheduleDate,
                            highlight: {
                                color: 'gray',
                                fillMode: 'light',
                            },
                            popover: {
                                label: this.$t(
                                    'cp__research_invitations__dates__reminder_date__picker_highlight_tooltip'
                                ),
                                visibility: 'hover',
                                hideIndicator: true,
                            },
                        } ]},model:{value:(_vm.reminderDate),callback:function ($$v) {_vm.reminderDate=$$v},expression:"reminderDate"}})],1),(_vm.reminderEnabled)?_c('p',{staticClass:"reminder_summary",domProps:{"innerHTML":_vm._s(
                    _vm.$t('cp__research_invitations__dates__reminder_date__summary', {
                        days: _vm.differenceInDaysSummary,
                    })
                )}}):_c('p',{staticClass:"reminder_summary"},[_vm._v(" "+_vm._s(_vm.$t("cp__research_invitations__dates__reminder_date__summary__null"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }