<template>
    <div class="form-container">
        <h1 class="headline5 title">
            {{ $t("cp__respondents_list_popup_preview_email_title") }}
        </h1>
        <div class="form">
            <section class="form-content">
                <label class="instruction-sub">{{
                    `${$t("cp__respondents_list_popup_preview_email_label")}:`
                }}</label>
                <TextInput
                    v-model="email"
                    label="Email"
                    enableExternalValidation
                    :externalValidationError="$v.email.$error"
                    :externalValidationErrorMessage="resolveErrorMessage($v.email)"
                />
            </section>
            <div class="form-footer">
                <div class="button-group">
                    <Button buttonStyle="secondary" @click.native="$emit('close')">{{
                        $t("cp__respondents_list_popup_cancel")
                    }}</Button>
                    <Button :loading="isLoading" @click.native="send">{{
                        $t("cp__respondents_list_popup_preview_email_confirm")
                    }}</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import { previewInvitationSchema } from "@/validation/schemas/previewInvitation";
import { resolveErrorMessage, validationMixin } from "@/validation";

export default {
    name: "PreviewInvitation",
    mixins: [validationMixin],
    components: {
        Button,
        TextInput,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    validations() {
        return previewInvitationSchema();
    },
    data() {
        return {
            email: null,
        };
    },
    methods: {
        resolveErrorMessage,
        send() {
            this.$v.$touch();
            if (!!this.email && !this.$v.$error) {
                this.$emit("send", this.email);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "../../style_variables/style_variables.scss";

.form-container {
    width: 100%;
    height: 100%;
}

.title {
    margin-bottom: 40px;
}

.form {
    width: 100%;
    height: 100%;
}

.form-content {
    margin-bottom: 24px;
    max-height: 50vh;
    overflow-y: auto;
}

.instruction-sub {
    @extend %body2_style;
    margin: 0 0 12px 0;
    letter-spacing: 0;
    display: block;
}

.form-footer {
    display: flex;
    justify-content: flex-end;
}

.button-group {
    display: flex;
    > :first-child {
        margin-right: 12px;
    }
}
</style>
