<template>
    <GenericPage
        class="research-invitations"
        :header-props="{
            title: $t('cp__research_invitations__title'),
            subtitle: $t('cp__research_invitations__subtitle'),
            disableBackButton: true,
        }"
    >
        <div class="container">
            <GenericBlock class="stepper-block">
                <Stepper
                    :stepSequence="stepSequence"
                    :activeStep="activeStep"
                    :allStepsCompleted="allStepsCompleted"
                    @moveBackTo="moveBackTo($event.stepId)"
                />
            </GenericBlock>
            <GenericBlock :clearStyles="clearStylesFromGenericBlock">
                <Questionnaire v-if="activeStep.id === stepIds.SELECT_QUESTIONNAIRE" />
                <RespondentsList
                    ref="respondents"
                    v-else-if="activeStep.id === stepIds.CREATE_RESPONDENTS_LIST"
                    @requestColsFile="exportDefaultColsToExcel"
                    @exportSelected="exportRespondentsToExcel"
                />
                <ScheduleInvitations
                    ref="schedule"
                    v-else-if="activeStep.id === stepIds.SCHEDULE_DATES"
                />
                <ConfirmInvitations v-else-if="activeStep.id === stepIds.CONFIRM_INVITATIONS" />
                <InvitationsScheduled v-else />
            </GenericBlock>
        </div>
        <SettingsFooter>
            <div
                class="button-container"
                :class="{ 'button-container-steps-completed': allStepsCompleted }"
            >
                <Button
                    :disabled="!nextStepIsAllowed"
                    @click.native="handleNext()"
                    :loading="loading"
                    >{{ $t(nextButtonLabel) }}</Button
                >
                <Button
                    v-if="allStepsCompleted"
                    class="export_result"
                    buttonStyle="secondary"
                    @click.native="exportResultsToExcel()"
                    :loading="loading"
                    >{{ $t("cp__research_invitations__btn_download_invitations") }}</Button
                >
                <Button
                    v-if="previousStep && !allStepsCompleted"
                    buttonStyle="secondary"
                    @click.native="moveBackTo(previousStep.id)"
                    :disabled="loading"
                >
                    {{ $t("cp__research_invitations__btn_previous") }}
                </Button>
            </div>
        </SettingsFooter>
        <ConfirmPopup
            v-if="confirmPreviousPopupActive || confirmSchedulePopupActive"
            :title="$t(confirmPopupLabels.title)"
            :message="$t(confirmPopupLabels.message)"
            :confirm-btn="$t('cp__research_invitations__previous_confirm__confirm')"
            :cancel-btn="$t('cp__generic__cancel')"
            :confirmBtnIsLoading="confirmPopupLoading"
            :disableCancelButton="confirmPopupLoading"
            @confirm="confirmSchedulePopupActive ? schedule() : proceedAfterPopupConfirm()"
            @cancel="cancelPopup"
        />
    </GenericPage>
</template>

<script>
import * as Sentry from "@sentry/vue";
import { mapGetters, mapState } from "vuex";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCalendarDay,
    faChevronRight,
    faClipboardList,
    faClipboardListCheck,
    faList,
} from "@fortawesome/pro-light-svg-icons";
import * as XLSX from "xlsx";
import moment from "moment";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import GenericBlock from "@/components/Global/Molecules/GenericBlock.vue";
import Stepper from "@/components/Global/Molecules/Stepper.vue";
import Questionnaire from "@/components/Invitations/Questionnaire.vue";
import RespondentsList from "@/components/Invitations/RespondentsList.vue";
import ScheduleInvitations from "@/components/Invitations/ScheduleInvitations.vue";
import ConfirmInvitations from "@/components/Invitations/ConfirmInvitations.vue";
import InvitationsScheduled from "@/components/Invitations/InvitationsScheduled.vue";
import { format } from "date-fns";
import sendInvitations from "../graphql/invitations/sendInvitations.gql";

library.add(
    faCalendarDay,
    faChevronRight,
    faCheckCircle,
    faClipboardList,
    faClipboardListCheck,
    faList
);

export default {
    name: "ResearchInvitations",
    components: {
        Button,
        ConfirmPopup,
        GenericPage,
        GenericBlock,
        SettingsFooter,
        Stepper,
        Questionnaire,
        RespondentsList,
        ScheduleInvitations,
        ConfirmInvitations,
        InvitationsScheduled,
    },
    data() {
        return {
            confirmPreviousPopupActive: false,
            confirmPreviousPopupTargetStep: null,
            confirmSchedulePopupActive: false,
            confirmPopupLoading: false,
            invitationsResult: null,
        };
    },
    computed: {
        ...mapGetters({
            stepIds: "ResearchInvitationsStore/stepIds",
            activeStep: "ResearchInvitationsStore/activeStep",
            previousStep: "ResearchInvitationsStore/previousStep",
            allStepsCompleted: "ResearchInvitationsStore/allStepsCompleted",
            nextStepIsAllowed: "ResearchInvitationsStore/nextStepIsAllowed",
            moveToQuestionnaireRequiresConfirmation:
                "ResearchInvitationsStore/moveToQuestionnaireRequiresConfirmation",
            loading: "ResearchInvitationsStore/loading",
        }),
        ...mapState({
            stepSequence: (state) => state.ResearchInvitationsStore.stepSequence,
            steps: (state) => state.ResearchInvitationsStore.steps,
            dates: (state) => state.ResearchInvitationsStore.steps.SCHEDULE_DATES.data,
            respondentsData: (state) =>
                state.ResearchInvitationsStore.steps.CREATE_RESPONDENTS_LIST.data,
            selectedQuestionnaire: (state) =>
                state.ResearchInvitationsStore.steps.SELECT_QUESTIONNAIRE.data
                    .selectedQuestionnaire,
            defaultColumns: (state) =>
                state.ResearchInvitationsStore.steps.SELECT_QUESTIONNAIRE.data.defaultColumns,
        }),
        nextButtonLabel() {
            if (this.allStepsCompleted) return "cp__research_invitations__btn_send_more";
            if (this.activeStep.id === this.stepIds.CONFIRM_INVITATIONS)
                return "cp__research_invitations__btn_schedule";

            return "cp__research_invitations__btn_next";
        },
        confirmPopupLabels() {
            const labelTuple = {
                title: "cp__research_invitations__previous_confirm__title",
                message: "cp__research_invitations__previous_confirm__message",
            };
            if (this.confirmSchedulePopupActive) {
                labelTuple.title = "cp__research_invitations__schedule_confirm__title";
                labelTuple.message = "cp__research_invitations__schedule_confirm__message";
            }

            return labelTuple;
        },
        clearStylesFromGenericBlock() {
            return (
                this.activeStep.id === this.stepIds.CREATE_RESPONDENTS_LIST ||
                this.activeStep.id === this.stepIds.SCHEDULE_DATES ||
                this.activeStep.id === this.stepIds.CONFIRM_INVITATIONS
            );
        },
    },
    methods: {
        async handleNext() {
            if (this.activeStep.id === this.stepIds.CREATE_RESPONDENTS_LIST) {
                // CREATE_RESPONDENTS_LIST's handleNext() will trigger BE validation. When there are errors dont go to the next step.
                const success = await this.$refs.respondents.handleNext();
                if (!success) return;
            }
            if (this.activeStep.id === this.stepIds.SCHEDULE_DATES) {
                this.$refs.schedule.saveToStore();
            }

            if (this.activeStep.id === this.stepIds.CONFIRM_INVITATIONS) {
                this.confirmSchedulePopupActive = true;
            } else if (this.allStepsCompleted) {
                await this.$store.dispatch("ResearchInvitationsStore/reset");
            } else {
                await this.$store.dispatch("ResearchInvitationsStore/next");
            }
        },
        moveBackTo(targetStepId) {
            if (this.moveToQuestionnaireRequiresConfirmation(targetStepId)) {
                this.confirmPreviousPopupTargetStep = targetStepId;
                this.confirmPreviousPopupActive = true;
                return;
            }

            this.$store.dispatch("ResearchInvitationsStore/moveBackTo", { stepId: targetStepId });
        },
        async schedule() {
            try {
                this.confirmPopupLoading = true;
                const result = await this.$apollo.mutate({
                    mutation: sendInvitations,
                    variables: {
                        respondents: this.mapRespondentsForSave(this.respondentsData.respondents),
                        questionnaireId: this.selectedQuestionnaire.id,
                        sendDate: this.formatScheduleDate(this.dates.scheduleDate),
                        reminderDate: this.dates.reminderDate
                            ? this.formatScheduleDate(this.dates.reminderDate)
                            : "",
                    },
                });
                if (result.data.sendInvitations.success) {
                    this.confirmSchedulePopupActive = false;
                    this.invitationsResult = result.data.sendInvitations;
                    await this.$store.dispatch("ResearchInvitationsStore/next");
                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: this.$t("cp__research_invitations__success_messsage_title"),
                        message: this.$t("cp__research_invitations__success_messsage"),
                    });
                    this.confirmPopupLoading = false;
                } else {
                    this.showError();
                }
            } catch (errors) {
                /* eslint-disable no-console */
                console.log(errors);
                this.showError();
            }
        },
        formatScheduleDate(date) {
            return format(date, "yyyy-MM-dd HH:mm:ss");
        },
        async exportResultsToExcel() {
            await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                loading: true,
            });
            try {
                // get excel data
                const createdRecipients = this.getRecipientsExcelData(
                    this.invitationsResult.created,
                    true
                );
                const notCreatedRecipients = this.getRecipientsExcelData(
                    this.invitationsResult.notCreated,
                    false
                );

                // create excel sheets
                const createdRecipientsSheet = XLSX.utils.json_to_sheet(createdRecipients);
                const notCreatedRecipientsSheet = XLSX.utils.json_to_sheet(notCreatedRecipients);

                if (createdRecipients.length > 0) {
                    createdRecipientsSheet["!cols"] = this.getExcelColumnsWidth(
                        Object.keys(createdRecipients[0]).length
                    );
                }
                if (notCreatedRecipients.length > 0) {
                    notCreatedRecipientsSheet["!cols"] = this.getExcelColumnsWidth(
                        Object.keys(notCreatedRecipients[0]).length
                    );
                }
                createdRecipientsSheet.columns = [];
                notCreatedRecipientsSheet.columns = [];

                // create workbook
                const wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: "Invitations result",
                    Author: "Tevreden",
                };

                // add sheets to workbook
                XLSX.utils.book_append_sheet(wb, createdRecipientsSheet, "Created");
                XLSX.utils.book_append_sheet(wb, notCreatedRecipientsSheet, "Not Created");

                // generate file and send to client
                XLSX.writeFile(
                    wb,
                    `invitations_result_${moment().format("YYYY.MM.DD_HH.mm.ss")}.xlsx`
                );

                this.invitationsResultDownloaded = true;
                await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                    loading: false,
                });
            } catch (error) {
                Sentry.captureException(error);
                await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                    loading: false,
                });
            }
        },
        async exportDefaultColsToExcel() {
            await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                loading: true,
            });
            try {
                // get excel data
                const colsTemplate = this.getDefaultColsExcelData(this.defaultColumns);

                // create excel sheet
                const colsTemplateSheet = XLSX.utils.json_to_sheet(colsTemplate);

                // create workbook
                const wb = XLSX.utils.book_new();

                // add sheets to workbook
                XLSX.utils.book_append_sheet(
                    wb,
                    colsTemplateSheet,
                    `Default columns (${this.selectedQuestionnaire.id})`
                );

                // generate file and send to client
                XLSX.writeFile(
                    wb,
                    `columns_questionnaire_${this.selectedQuestionnaire.id.replace(
                        "riq_",
                        ""
                    )}.xlsx`
                );

                await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                    loading: false,
                });
            } catch (error) {
                Sentry.captureException(error);
                await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                    loading: false,
                });
            }
        },
        async exportRespondentsToExcel(eventData) {
            await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                loading: true,
            });
            try {
                const exportData = this.getRespondentExportExcelData(eventData);

                const exportDataSheet = XLSX.utils.json_to_sheet(exportData);

                const wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(
                    wb,
                    exportDataSheet,
                    `Exported respondents (${this.selectedQuestionnaire.id})`
                );

                XLSX.writeFile(wb, `exported_respondents_${this.selectedQuestionnaire.id}.xlsx`);

                await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                    loading: false,
                });
            } catch (error) {
                Sentry.captureException(error);
                await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                    loading: false,
                });
            }
        },
        getRespondentExportExcelData(exportData) {
            const excelData = [];
            exportData.selectedRespondents.forEach((resp) => {
                const item = {};
                exportData.columns.forEach((col) => {
                    item[col.label] = resp[col.label];
                });
                excelData.push(item);
            });
            return excelData;
        },
        getDefaultColsExcelData(cols) {
            const excelData = [];
            const item = {};
            cols.forEach((col) => {
                item[col.name] = "";
            });
            excelData.push(item);
            return excelData;
        },
        getRecipientsExcelData(recipients, questionnaireDataFirst) {
            const excelData = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < recipients.length; i++) {
                excelData.push(this.getRecipientExcelData(recipients[i], questionnaireDataFirst));
            }
            return excelData;
        },
        getRecipientExcelData(recipient, questionnaireDataFirst) {
            const excelData = {};

            if (questionnaireDataFirst) {
                this.setQuestionnaireExcelData(excelData, recipient);
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const key in recipient.data) {
                // eslint-disable-next-line no-prototype-builtins, no-continue
                if (!recipient.data.hasOwnProperty(key)) continue;
                excelData[`Recipient - ${key}`] = recipient.data[key];
            }

            excelData.Message = recipient.message;
            excelData["Send date"] = recipient.sendDate;
            excelData["Reminder date"] = recipient.reminderDate;

            if (!questionnaireDataFirst) {
                this.setQuestionnaireExcelData(excelData, recipient);
            }

            return excelData;
        },
        setQuestionnaireExcelData(excelData, recipient) {
            excelData["Questionnaire ID"] = recipient.questionnaire
                ? recipient.questionnaire.id
                : "";
            excelData["Questionnaire subject"] = recipient.questionnaire
                ? recipient.questionnaire.subject
                : "";
            excelData["Questionnaire name"] = recipient.questionnaire
                ? recipient.questionnaire.name
                : "";
            excelData["Organisation ID"] = recipient.questionnaire
                ? recipient.questionnaire.organisationid
                : "";
            excelData["Location ID"] = recipient.questionnaire
                ? recipient.questionnaire.locationid
                : "";
        },
        getExcelColumnsWidth(numOfColumns) {
            const columnsWidth = [{ wpx: 100 }, { wpx: 150 }, { wpx: 150 }];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < numOfColumns - 3; i++) {
                columnsWidth.push({ wpx: 120 });
            }

            return columnsWidth;
        },
        showError() {
            this.confirmSchedulePopupActive = false;
            this.$store.dispatch("pushNotification", {
                type: "error",
                title: this.$t("cp__pn__config_invitation__error__title", "Error"),
                message: this.$t("cp__research_invitations__error_messsage"),
            });
            this.confirmPopupLoading = false;
        },
        mapRespondentsForSave(respondents) {
            respondents.forEach((el) => {
                delete el._id;
                delete el.createdAt;
                delete el.isSelected;
                delete el.error;
            });
            return respondents;
        },
        async proceedAfterPopupConfirm() {
            await this.$store.dispatch("ResearchInvitationsStore/moveBackTo", {
                stepId: this.confirmPreviousPopupTargetStep,
            });
            this.confirmPreviousPopupActive = false;
            this.confirmPreviousPopupTargetStep = null;
        },
        cancelPopup() {
            this.confirmSchedulePopupActive = false;
            this.confirmPreviousPopupActive = false;
            this.confirmPreviousPopupTargetStep = null;
        },
    },
    async destroyed() {
        await this.$store.dispatch("ResearchInvitationsStore/reset");
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "../style_variables/style_variables.scss";

.research-invitations {
    position: relative;
    overflow: auto;
    @include viewportHeight(100, calc(56px + 96px));
    @media (max-width: 1000px) {
        @include viewportHeight(100, 144px);
    }
}
.container {
    margin: 40px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media($isPhone...) {
        margin-left: 16px;
        margin-right: 16px;
    }
}
.stepper-block {
    margin-bottom: 40px;
}
.button-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.button-container-steps-completed {
    justify-content: end;
}
.export_result {
    margin-right: 10px;
}
</style>
