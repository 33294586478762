import { required, requiredIf, email, isNotDuplicateEmail } from "@/validation/validators";

export const getRespondentSchema = (model, emails) => {
    const respondent = {};
    model.forEach((property) => {
        respondent[property.property] = {
            required: requiredIf(() => property.isRequired),
            email: property.property === "email" ? email : "",
            isNotDuplicateEmail: property.property === "email" ? isNotDuplicateEmail(emails) : "",
        };
    });
    return { respondent };
};

export const getHeaderFieldsSchema = () => ({
    headerColumns: {
        $each: {
            label: { required },
        },
    },
});
