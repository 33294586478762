<template>
    <div class="schedule_invitations">
        <div class="confirmation_message">
            {{
                $t("cp__research_invitations__confirmation_message", {
                    date: formatDate(dates.scheduleDate),
                })
            }}
        </div>
        <img
            alt="Image"
            class="confirmation_illustration"
            src="../../assets/invitations/invitation-confirmation.svg"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";

export default {
    name: "InvitationsScheduled",
    data() {
        return {};
    },
    computed: {
        ...mapState({
            dates: (state) => state.ResearchInvitationsStore.steps.SCHEDULE_DATES.data,
            currentLanguage: (state) => state.LanguageStore.currentLanguage,
        }),
    },
    methods: {
        formatDate(date) {
            return this.currentLanguage === "en"
                ? format(date, "MMMM dd, yyyy")
                : date.toLocaleString("nl-NL", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                  });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables";
@import "~include-media";

.schedule_invitations {
    display: flex;
    flex-direction: column;
    padding: 110px 0;
}
.confirmation_message {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    width: 520px;
    padding-bottom: 60px;
    align-self: center;
}
.confirmation_illustration {
    width: 375px;
    align-self: center;

    @include media(">=tablet") {
        width: 440px;
    }
}
</style>
