<template>
    <div class="schedule_invitations">
        <SettingsBlock :title="$t('cp__research_invitations__dates__title')">
            <SettingsSection
                class="schedule_date_input"
                :label="$t('cp__research_invitations__dates__invitations_date__label')"
                :helpText="$t('cp__research_invitations__dates__invitations_date__helptext')"
            >
                <div class="date_input_container">
                    <DateInput
                        v-model="scheduleDate"
                        mode="dateTime"
                        isRequired
                        :placeholder="$t('inputs__date__select_date')"
                        :minDate="new Date()"
                        :locale="getLanguageCultureCode"
                    />
                </div>
            </SettingsSection>

            <SettingsSection
                :label="$t('cp__research_invitations__dates__reminder_date__label')"
                :helpText="$t('cp__research_invitations__dates__reminder_date__helptext')"
            >
                <Checkbox
                    v-model="reminderEnabled"
                    :label="$t('cp__research_invitations__dates__reminder_date__checkbox_label')"
                    class="checkbox"
                />
                <div class="date_input_container">
                    <DateInput
                        v-model="reminderDate"
                        mode="dateTime"
                        isRequired
                        :placeholder="$t('inputs__date__select_date')"
                        :forcePlaceholder="!reminderEnabled"
                        :minDate="reminderDateMinDate"
                        :locale="getLanguageCultureCode"
                        :disabled="!reminderEnabled"
                        :attributes="[
                            {
                                dates: this.scheduleDate,
                                highlight: {
                                    color: 'gray',
                                    fillMode: 'light',
                                },
                                popover: {
                                    label: this.$t(
                                        'cp__research_invitations__dates__reminder_date__picker_highlight_tooltip'
                                    ),
                                    visibility: 'hover',
                                    hideIndicator: true,
                                },
                            },
                        ]"
                    />
                </div>
                <p
                    v-if="reminderEnabled"
                    class="reminder_summary"
                    v-html="
                        $t('cp__research_invitations__dates__reminder_date__summary', {
                            days: differenceInDaysSummary,
                        })
                    "
                />
                <p v-else class="reminder_summary">
                    {{ $t("cp__research_invitations__dates__reminder_date__summary__null") }}
                </p>
            </SettingsSection>
        </SettingsBlock>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Checkbox from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Checkbox.vue";
import DateInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/DateInput.vue";
import { addDays, differenceInCalendarDays } from "date-fns";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";

export default {
    name: "ScheduleInvitations",
    components: {
        Checkbox,
        DateInput,
        SettingsBlock,
        SettingsSection,
    },
    data() {
        return {
            scheduleDate: new Date(),
            reminderEnabled: true,
            reminderDate: addDays(new Date(), 5),
        };
    },
    computed: {
        ...mapState({
            respondentsData: (state) =>
                state.ResearchInvitationsStore.steps.CREATE_RESPONDENTS_LIST.data,
            stepId: (state) => state.ResearchInvitationsStore.stepIds.SCHEDULE_DATES,
            dates: (state) => state.ResearchInvitationsStore.steps.SCHEDULE_DATES.data,
        }),
        ...mapGetters({
            getLanguageCultureCode: "LanguageStore/getLanguageCultureCode",
        }),
        reminderDateMinDate() {
            return addDays(this.scheduleDate, 5);
        },
        differenceInDaysSummary() {
            return differenceInCalendarDays(this.reminderDate, this.scheduleDate);
        },
    },
    watch: {
        scheduleDate(newScheduleDate) {
            // reset the reminder date when the scheduleDate day changes beyond the 5 day diff
            if (differenceInCalendarDays(this.reminderDate, newScheduleDate) >= 5) return;
            // then set new default reminderDate
            this.setDefaultReminderDate();
        },
    },
    methods: {
        setDefaultReminderDate() {
            const dayDifference = differenceInCalendarDays(this.reminderDate, this.scheduleDate);
            if (dayDifference >= 5) return;
            this.reminderDate = addDays(this.scheduleDate, 5);
        },
        saveToStore() {
            this.$store.commit("ResearchInvitationsStore/setData", {
                stepId: this.stepId,
                data: {
                    scheduleDate: this.scheduleDate,
                    reminderDate: this.reminderEnabled ? this.reminderDate : null,
                    reminderEnabled: this.reminderEnabled,
                },
            });
        },
    },
    beforeCreate() {
        this.$store.commit(
            "ResearchInvitationsStore/setMovingToQuestionnaireRequiresConfirmation",
            {
                stepId: this.$store.state.ResearchInvitationsStore.stepIds.SCHEDULE_DATES,
                predicate: () => !!this.respondentsData?.respondents.length,
            }
        );
        this.$store.commit("ResearchInvitationsStore/setNextStepPredicate", {
            stepId: this.$store.state.ResearchInvitationsStore.stepIds.SCHEDULE_DATES,
            predicate: () => !(this.reminderEnabled && !this.reminderDate),
        });
    },
    created() {
        if (this.dates) {
            this.reminderDate = this.dates.reminderDate;
            this.scheduleDate = this.dates.scheduleDate;
            this.reminderEnabled = this.dates.reminderEnabled;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables";

.date_input_container {
    max-width: 330px;
}

.schedule_date_input {
    margin-bottom: 56px;
}

.checkbox {
    margin: 4px 0 12px;
}

.reminder_summary {
    @extend %body2_style;
    margin-bottom: 0;
}
</style>
