<template>
    <div class="schedule_invitations">
        <SettingsBlock :title="$t('cp__research_invitations__confirm_invitations__title')">
            <div class="invitation_summary_title">
                <label class="subtitle1"
                    ><span
                        v-html="$t('cp__research_invitations__confirm_invitations__text')"
                    ></span>
                </label>
            </div>
            <div class="invitation_summary">
                <div class="invitation_summary_part_1">
                    <div v-if="location" class="invitation_summary_field">
                        <div class="invitation_summary_field_title">
                            {{ $t("cp__research_invitations__confirm_invitations__location") }}
                        </div>
                        <div>{{ location }}</div>
                    </div>
                    <div class="invitation_summary_field">
                        <div class="invitation_summary_field_title">
                            {{ $t("cp__research_invitations__confirm_invitations__questionnare") }}
                        </div>
                        <div>{{ selectedQuestionnaire.name }}</div>
                    </div>

                    <div class="invitation_summary_field">
                        <div class="invitation_summary_field_title">
                            {{ $t("cp__research_invitations__confirm_invitations__respondents") }}
                        </div>
                        <div>{{ respondentsData.respondents.length }}</div>
                    </div>
                </div>

                <div class="invitation_summary_part_2">
                    <div class="invitation_summary_field">
                        <div class="invitation_summary_field_title">
                            {{ $t("cp__research_invitations__confirm_invitations__invitations") }}
                        </div>
                        <div>{{ formatDate(dates.scheduleDate) }}</div>
                    </div>
                    <div class="invitation_summary_field">
                        <div class="invitation_summary_field_title">
                            {{ $t("cp__research_invitations__confirm_invitations__reminder") }}
                        </div>
                        <div>{{ formatDate(dates.reminderDate) }}</div>
                    </div>
                    <div class="invitation_summary_field">
                        <div class="invitation_summary_field_title">
                            {{ $t("cp__research_invitations__confirm_invitations__questid") }}
                        </div>
                        <div>{{ selectedQuestionnaire.id.replace(/riq_/, "") }}</div>
                    </div>
                </div>
            </div>
        </SettingsBlock>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import * as Sentry from "@sentry/vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import getLocation from "../../graphql/invitations/getLocation.gql";

export default {
    name: "ConfirmInvitations",
    components: {
        SettingsBlock,
    },
    data() {
        return {
            location: "",
        };
    },
    computed: {
        ...mapState({
            respondentsData: (state) =>
                state.ResearchInvitationsStore.steps.CREATE_RESPONDENTS_LIST.data,
            dates: (state) => state.ResearchInvitationsStore.steps.SCHEDULE_DATES.data,
            selectedQuestionnaire: (state) =>
                state.ResearchInvitationsStore.steps.SELECT_QUESTIONNAIRE.data
                    .selectedQuestionnaire,
        }),
    },
    methods: {
        async getLocation() {
            try {
                const result = await this.$apollo.query({
                    query: getLocation,
                    variables: { questionnaireId: this.selectedQuestionnaire.id },
                });
                this.location = result.data.location.name;
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        formatDate(date) {
            return date ? format(date, "dd-MM-yyyy HH:mm") : "-";
        },
    },
    async created() {
        await this.getLocation();
    },
    beforeCreate() {
        this.$store.commit(
            "ResearchInvitationsStore/setMovingToQuestionnaireRequiresConfirmation",
            {
                stepId: this.$store.state.ResearchInvitationsStore.stepIds.CONFIRM_INVITATIONS,
                predicate: () => !!this.respondentsData?.respondents.length,
            }
        );
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables";

.invitation_summary_title {
    max-width: 470px;
    padding-top: 30px;
}
.invitation_summary {
    @extend %subtitle1_style;
    margin-top: 12px;
    border-radius: 4px;
    border: 1px solid $grey_alabaster;
    padding: 24px;
    padding-right: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.invitation_summary_field {
    padding-bottom: 15px;
    &:last-of-type {
        padding-bottom: 0px;
    }
}

.invitation_summary_field_title {
    color: $grey_bombay;
}

.invitation_summary_part_1 {
    display: flex;
    flex-direction: column;
}

.invitation_summary_part_2 {
    display: flex;
    flex-direction: column;
    width: 300px;
}
</style>
