<template>
    <div class="form-container">
        <h1 class="headline5 title">{{ $t("cp__respondents_list_popup_title") }}</h1>
        <div class="form">
            <section class="form-content">
                <p class="instruction-main">
                    {{ $t("cp__respondents_list_popup_instructions_main") }}
                </p>
                <p class="instruction-sub">
                    {{ $t("cp__respondents_list_popup_instructions_sub") }}
                </p>
                <FileInput
                    class="file-input"
                    placeholderText="xls/xlsx/csv"
                    v-model="file"
                    type=".xls,.xlsx,.csv"
                    :validationError="false"
                    validationErrorMessage=""
                    :buttonLabel="$t('cp__respondents_list_popup_choose_file_button')"
                />
            </section>
            <div class="form-footer">
                <Button
                    buttonStyle="secondary"
                    @click.native="showAddRespondent"
                    :disabled="loading"
                    >{{ $t("cp__respondents_list_popup_add") }}</Button
                >
                <div class="button-group">
                    <Button buttonStyle="secondary" @click.native="$emit('close')">{{
                        $t("cp__respondents_list_popup_cancel")
                    }}</Button>
                    <Button @click.native="upload" :disabled="!file" :loading="loading">{{
                        $t("ADD")
                    }}</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FileInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/FileInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import { mapState } from "vuex";
import uploadRespondents from "@/graphql/invitations/uploadRespondents.gql";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";

export default {
    name: "RespondentsUpload",
    components: {
        Button,
        FileInput,
    },
    data() {
        return {
            file: null,
            loading: false,
        };
    },
    computed: {
        ...mapState({
            selectedQuestionnaire: (state) =>
                state.ResearchInvitationsStore.steps.SELECT_QUESTIONNAIRE.data
                    .selectedQuestionnaire,
        }),
    },
    methods: {
        async upload() {
            this.loading = true;

            try {
                const { data } = await this.$apollo.mutate({
                    mutation: uploadRespondents,
                    variables: {
                        respondents: this.file,
                        questionnaireId: this.selectedQuestionnaire.id.split("_")[1],
                    },
                });

                this.$emit("uploadSuccess", { data: data.uploadRespondents });
                this.$emit("close");
            } catch (errors) {
                let message = this.$t("cp__generic__error");
                if (resolveGraphQLErrors(errors).includes("INVITATION_UPLOAD_LIMIT_EXCEEDED")) {
                    message = this.$t("cp__respondents_list_error_respondent_limit_exceeded");
                }
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__respondents_list_error_upload"),
                    message,
                });
            }

            this.loading = false;
        },
        showAddRespondent() {
            this.$emit("showAddRespondent");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "../../style_variables/style_variables.scss";

.form-container {
    width: 100%;
    height: 100%;
}

.title {
    margin-bottom: 40px;
}

.instruction-main {
    @extend %body1_style;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0;
}

.instruction-sub {
    @extend %body2_style;
    margin: 0 0 12px 0;
    letter-spacing: 0;
}

.form {
    width: 100%;
    height: 100%;
}

.file-input::v-deep .input {
    max-width: none;
}

.form-content {
    margin-bottom: 24px;
    @include media($isDesktop...) {
    }
}

.form-footer {
    display: flex;
    justify-content: space-between;
}

.button-group {
    display: flex;
    > :first-child {
        margin-right: 12px;
    }
}
</style>
